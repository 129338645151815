<template>
  <div>
    <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-end">
      <b-button type="button" variant="primary" id="resRefresh" @click="getResData()" class="mx-0">
        <Icon type="f" /> {{ $t('btn.f') }}
      </b-button>
    </b-row>
    <ReservationsUserTable v-if="isUser" ref="resTabNxt1" suffix="resTabNxtwU"
        :query="resTabDefU"
        showShow showCancel showDelete showPagination
        noHitLink="/reservations/new" :noHitText="noHitText || $t('res.rno')" />
    <ReservationsCustomerTable v-if="!isUser" ref="resTabNxt2" suffix="resTabNxtC"
        :query="resTabDefC" :noHitText="noHitText || $t('res.rno')"
        showShow showAccept showDelete showReject showConfirm showPagination />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import ReservationsUserTable from '@/components/reservations/UserTable';
import ReservationsCustomerTable from '@/components/reservations/CustomerTable';

export default {
  data() {
    return {
      resTabDefU: { q: 'startAt:ge:{N},state:n:CANCELED', s: '+startAt', p: '10' },
      resTabDefC: { q: 'startAt:ge:{T+1},state:n:REJECTED,userUuid4:n:{U}', s: '+startAt', p: '10' },
    };
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
  },
  methods: {
    getResData() {
      if (this.isUser) {
        this.$refs['resTabNxt1'].getResData();
      } else {
        this.$refs['resTabNxt2'].getResData();
      }
    },
  },
  props: {
    noHitText: {
      type: String,
      default: null,
    },
  },
  components: {
    ReservationsUserTable,
    ReservationsCustomerTable,
    Icon,
  }
}
</script>
